var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _vm.api.isLoading
        ? _c("v-skeleton-loader", { ref: "skeleton", attrs: { type: "table" } })
        : _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              items: _vm.data,
              headers: _vm.dataHeader,
              search: _vm.search,
              dense: "",
            },
            scopedSlots: _vm._u([
              {
                key: "item.Index",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _vm._v(" " + _vm._s(_vm.data.indexOf(item) + 1) + " "),
                  ]
                },
              },
              {
                key: "item.start_at",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    item.start_at != null
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.convertTimeZone(item.start_at)) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "item.end_at",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    item.end_at != null
                      ? _c("span", [
                          _vm._v(
                            " " + _vm._s(_vm.convertTimeZone(item.end_at)) + " "
                          ),
                        ])
                      : _c("span", [_vm._v(" - ")]),
                  ]
                },
              },
              {
                key: "item.pre_order",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    item.pre_order == 1
                      ? _c(
                          "v-icon",
                          { attrs: { color: "green", size: "30", dense: "" } },
                          [_vm._v(" bi-check ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "top",
                fn: function () {
                  return [
                    _c(
                      "v-toolbar",
                      { attrs: { flat: "", height: "autp" } },
                      [
                        _c(
                          "v-col",
                          [
                            _c(
                              "v-row",
                              { staticClass: "mt-2 mx-3" },
                              [
                                _c(
                                  "v-col",
                                  [
                                    _c(
                                      "v-toolbar-title",
                                      {
                                        staticClass:
                                          "font-weight-bold text-h4 my-3",
                                      },
                                      [_vm._v(" Rewards ")]
                                    ),
                                  ],
                                  1
                                ),
                                _c("v-col", [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex justify-end" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "primary",
                                            to: { name: "PageRewardCreate" },
                                          },
                                        },
                                        [_vm._v(" New ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              { staticClass: "mx-3" },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    label: "search",
                                    dense: "",
                                    outlined: "",
                                  },
                                  model: {
                                    value: _vm.search,
                                    callback: function ($$v) {
                                      _vm.search = $$v
                                    },
                                    expression: "search",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "item.name",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "PageRewardDetail",
                            params: { id: item.id },
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.name) + " ")]
                    ),
                  ]
                },
              },
            ]),
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }